/* font-family: 'Ibarra Real Nova', serif;
font-family: 'Inconsolata', monospace;
font-family: 'PT Serif', serif; */

html {
  background: rgb(85, 85, 85);
  color: white;
  font-family: 'Inconsolata', monospace;
  scroll-behavior: smooth;
}

.title {
  font-size: 50px;
  margin: 10px;
  letter-spacing: 2px;
}

#jump-about {
  position: absolute;
  height: 50px;
}
#jump-language {
  position: absolute;
  height: 40px;
}
#jump-projects {
  position: absolute;
  height: 50px;
}
#jump-contact {
  position: absolute;
  height: 45px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inconsolata', monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.about {
  margin-top: 50px;
  background: rgb(109, 109, 109);
  padding: 30px;
  box-shadow: 0px 5px 10px rgb(65, 65, 65);
}

.head-shot {
  border-radius: 50%;
  height: 265px;
}


.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    width: 100%;
}

.no-space  {
  margin: 20px 0 20px 0;
}

.skills-out {
  width: 100%;
  z-index: 2;
}

.line {
  height: 0px;
  border-bottom: 1px solid gray;
  width: 100%;
  box-shadow:0px 2px 5px #202020;
  max-width: 1155px;
}

.abt {
   box-shadow:0px 2px 5px #383838; 
}

.prj {
  border-bottom: 1px solid rgb(219, 218, 218);
  box-shadow:0px 2px 5px #292929; 
}

.languages {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-height: 150px;
  flex-wrap: wrap;
  width: 100%;
}

.language {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  max-width: 100px;
}

.circle {
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.904);
  border-radius: 50%;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang {
  height: 50px;
  width: 50px;
  font-size: 17px;
  text-align: left;
  font-weight: 500;
  letter-spacing: 2px;
  
}

.aws {
  height: 45px;
  width: 65px;
}

.cat {
  font-size: 15px;
  font-family: 'Lato', sans-serif;
  text-align: left;
  letter-spacing: 2px;
  font-weight: normal;
}


.main-cat {
  margin: 20px 0px 20px 0px;
  font-size: 30px;
}

.mobile {
  display: block;
}
.non-mobile {
  display: none;
}

.nav-bar {
  width: 100%;
  position: fixed;
  top: 0px;
  box-shadow: 0px 5px 10px rgb(46, 46, 46);
  background: rgb(85, 85, 85);
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
}

.nav-left {
  display: flex;
  flex-direction: row;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 10px;
  font-family: 'Inconsolata', monospace;
  font-weight: bold;
  letter-spacing: 2px;
}

.name {
  background: white;
  color: black;
}

.paragraphs {
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  padding: 10px;
  text-align: left;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 20px;
  word-wrap: break-word
}

.fa-bars {
  margin: 20px;
  font-size: 30px;
}

.projects {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: rgb(128, 126, 126);
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 5px 10px rgb(22, 22, 22);
}

.grad-grey {
  height: 10px;
  background-image: linear-gradient(rgba(70, 70, 70, 0.664),rgb(145, 145, 145));
}

.proj-pic {
  width: 98%;
  box-shadow: 0px 5px 10px rgb(31, 31, 31);
  border-radius: 5px;
}

.slwclwd {
  width: 98%;
  height: auto;
}

.sqr-pic {
  width: 98%;
}

.proj-title {
  text-align: left;
  font-size: 30px;
  padding: 10px 0 10px 0;
  margin: 10px;
  letter-spacing: 0px;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  border-bottom: 2px solid rgba(255, 255, 255, 0.774);
  width: 100%;
}

.proj {
  width: 100%;
  padding: 20px 0px 0px 0px;
  font-size: 35px;
  letter-spacing: 3px;
  box-shadow: 0px 2px 5px rgba(77, 77, 77, 0.562) inset
}

.sqr {
  background: rgb(139, 137, 137);
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: rgb(53, 53, 53);
}

.contacts {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.con {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 110px;
  margin: 10px;
  border: 3px solid rgba(255, 255, 255, 0.712);
  font-family: 'Lato', sans-serif;
  letter-spacing: 2px;
  font-weight: normal;
  box-shadow: 0px 5px 10px rgb(22, 22, 22); 
}

.con-right {
  width: 100%;
  margin: 0 60px 0 0px;
}

.con-left {
  padding: 10px 10px 10px 10px;
}

.icon {
  padding: 10px;
  font-size: 40px;
}

.con-sub {
  margin: 5px;
}

.con-sides {
  width: 100%;
}

.test-mailing {
  width: 100%;
}

.line-form {
  margin: 10px 10px 10px 0px;
}

.form-info {
  margin-top: 20px;
}

.form-sub {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.text-field {
  color: white;
  margin: 10px 0 10px 0;
  height: 40px;
  width: 100%;
  border: none;
  background: rgb(82, 81, 81);
  text-decoration: none;
  outline-width: 0;
  font-size: 18px;
}

.large {
  height: 240px;
  text-align: start;
  resize: vertical;
  touch-action: manipulation;
}

.contacts-sub {
  display: flex;
  flex-direction: row;
}

.form-shift {
  width: 100%;
}

.large-top {
  margin-top: 10px
}

.form-success {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 45vh;
  /* left: 10vw; */
  /* bottom: 250px; */
  /* left: 500px; */
  width: 350px;
  height: 300px;
  font-size: 50px;
  background: rgba(216, 216, 216, 0.87);
  border-radius: 5px;
  border: 3px solid white;
}

.display {
    animation: fadeinout 3s ease-out;	
}

.display-none {
  display: none;
  animation-name: unclick;
  animation-duration: 1s;
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  20%,80% { opacity: 1; }
}


.form-succ {
  width: 240px;
}

.form-p {
  display: flex;
  justify-content: center;
  width: 260px;
}

#copyright {
  background: rgb(53, 53, 53);
  padding: 20px;
}

.failure {
  border: 3px solid rgb(255, 220, 220);
  background: rgb(255, 155, 155);
  color: rgb(253, 239, 239);
}

.fl {
  text-align: center;
  width: 300px;
}

.btn {
  height: 42px;
  font-size: 15px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  letter-spacing: 3px;
  color: white;
  background: rgb(105, 105, 105);
  border: none;
  margin: 10px;
  
}

.btn-clear {
  background: rgba(0, 0, 0, 0.144);
  border: 3px solid rgb(158,158,158);
  color: rgb(158, 158, 158);
}

*:focus {
    outline: none;
}

.project-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn-prj {
  width:170px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: rgba(46, 46, 46, 0.89);
}

.btn-prj-two {
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid rgb(216, 216, 216);
  color: rgb(226, 226, 226);
}

.menu-dropdown {
  width: 200px;
  position: fixed;
  top: 0px;
  right: 0px;
  box-shadow: 0px 5px 10px rgb(46, 46, 46);
  background: rgb(85, 85, 85);
  height: 250px;
  font-size: 20px;
  display: none;
 }

 .onClick {
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  font-family: 'Lato', sans-serif;
  animation-name: click;
  animation-duration: 500ms;
 }

 .slideUp {
   animation-name: unclick;
   animation-duration: 500ms;
 }

@keyframes click {
  from {
    top: -200px;
    }
  to {
    top: 50px;
  }
}
@keyframes unclick {
  from {
    top: 50px;
    }
  to {
    top: -250px;
  }
}

.menu-item {
  letter-spacing: 3px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 10px rgb(65, 65, 65);
}

.no-bottom {
  border-bottom: none;
}

.slwcld-proj {
  box-shadow: none;
}














































@media only screen and (min-width: 400px) {
  .form-success {
    width: 400px;
    /* left: 15vw; */
  }
}





@media only screen and (min-width: 450px) {
  .proj {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .proj-title {
    width: inherit;
  }

  .slwclwd {
    width: 95%;
  }

  .sqr-pic {
    width: 95%;
  }

  .proj-pic {
    width: 95%;
  }

  .proj-text {
    width: 95%;
  }

  .project-buttons {
    margin-bottom: 10px;
  }

  .prj {
    box-shadow: none;
    width: 90%;
  }

  .form-success {
    /* left: 25vw; */
  }
}




@media only screen and (min-width: 760px) {
  .form-success {
    /* left: 25vw; */
  }

  .tablet-sizing {
    justify-content: center;
  }

  .language {
    padding: 10px;
  }

  .form-sub {
    padding: 0 20px 0 20px;
  }

  .slwclwd {
    width: 90%;
  }

  .sqr-pic {
    width: 90%;
  }

  .proj-pic {
    width: 90%;
  }



}


@media only screen and (min-width: 1120px) {
  .mobile {
    display: none;
  }

  .non-mobile {
    display: block;
  }

  .invisible-projects {
    height: 1640px;
  }
  .invisible-skills {
    height: 457px;
  }


  #jump-about {
    /* position: absolute; */
    height: 50px;
  }
  #jump-language {
    /* position: absolute; */
    height: 40px;
  }
  #jump-projects {
    /* position: absolute; */
    height: 50px;
  }
  #jump-contact {
    /* position: absolute; */
    height: 45px;
  }


  .about {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .head-shot {
    border-radius: 50%;
    margin-left: 100px;
  }

  .skills-out {
    position: absolute;
  }

  .languages {
    justify-content: center;
  }

  .language {
    padding: 20px;
    height: 125px;
    width: 150px;
  }

  .circle {
    height: 90px;
    width: 90px;
  }

  .lang {
    height: 60px;
    width: 60px;
  }

  .aws {
    height: 55px;
    width: 75px;
  }

  .cat {
    font-size: 17px;
  }


  .main-cat {
    font-size: 35px;
    letter-spacing: 3px;
  }

  .name {
    background: white;
    color: black;
  }

  .paragraphs {
    margin: 10px;
  }

  .about-right {
    margin: 50px 100px 50px 50px;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    width: 1000px;
    align-items: flex-start;
  }



  .projects {
    position: absolute;
  }

  .proj-pic {
    height: 450px;
    width: 650px;
    position: relative;
    left: -150px
  }

  .slwclwd {
    height: 450px;
    width: 650px;
  }

  .sqr-pic {
    position: relative;
    left: 150px;
  }

  .proj-title {
    width: 90%;
  }

  .proj {
    flex-direction: row;
    justify-content: center;
    padding: 50px 0 20px 0;
  }

  .proj-text {
    position: relative;
    /* margin: 50px; */
    max-width: 663px;
  }

  .projecto {
    width: 90%;
    margin: 10px;
    padding: 0px;
  }

  .sqrgrv {
    margin: 50px 0px 50px 150px;
    /* width: 700px; */
  }

  .sqr {
    flex-direction: row-reverse;
  }

  .contacts {
    flex-direction: column;
  }


  .con-right {
    width: 30%;
    border-left: 1px solid gray;
    height: 580px;
  }

  .con-left {
    min-width: 700px;
    width: 70%;
    margin: 20px 0px 50px 100px;
  }

  .con-sides {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .test-mailing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .line-form {
    width: 197px;
  }

  .form-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .form-sub {
    width: 100%;
    padding: 0px;
    margin: 0px;
  }

  .form-sub-left {
    margin-right: 10px
  }

  .form-sub-right {
    margin-left: 10px;
  }

  .text-field {
    margin: 20px 0px 20px 0;
  }

  .large {
    width: 100%;
    margin: 20px 0 20px 0;
    padding: 0px;
  }

  .form-shift {
    width: 90%;
    margin: 0px;
    padding: 0px;
  }

  .btn {
    width: 250px;
    margin: 30px 30px 0px 0;
  }

  .btn-clear {
    width: 200px;
  }

  .project-buttons {
    justify-content: flex-start;
    margin-left: 10px;  
  }
}


@media only screen and (min-width: 1120px) {
  .form-success {
    /* left: 45vw; */
  }

}

/* margin takes in 2 values : margin (0, auto) */